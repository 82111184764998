/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
    padding: 20px 20px 0 20px;  
    .page-title{
        margin-bottom: 0px;
        color: $info;
    }
    .breadcrumb {
        padding: 0px;
        margin: 0px;
        background: transparent;
        font-size: 12px;
        .breadcrumb-item+.breadcrumb-item::before {
            content: "\e649";
            font-family: themify;
            color: $gray-300;
            font-size: 11px;
        }
        .breadcrumb-item.active {
            color: $muted;
        }
    }
}
