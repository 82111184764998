// this is margin-padding class override

// this is bootstrap override

@import "rtl-padding-margin";
@import "horizontal-rtl";

[dir="rtl"] {
  @import "spacing-rtl";

  text-align: right;
  direction: rtl;

  .page-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-right: 0.5rem;
    padding-left: 0;
    &::before {
      content: "\e64a";
      padding-right: 0;
      padding-left: 0.5rem;
    }
  }

  .steamline {
    border-right: 1px solid rgba(120, 130, 140, 0.13);
    border-left: 0;
    margin-right: 20px;
    margin-left: 0;
    .sl-left {
      margin-right: -20px;
      margin-left: 15px;
      float: right;
    }
    .sl-right {
      padding-right: 30px;
      padding-left: 0;
    }
  }

  .lstick {
    margin-right: -20px;
    margin-left: 18px;
  }

  .custom-select {
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
    //background: url(../../../assets/images/custom-select.png) left 0.35rem center no-repeat $white;
  }

  .custom-control {
    padding-left: 0;
    padding-right: 1.5rem;
  }

  .custom-control-label::after,
  .custom-control-label::before {
    left: auto;
    right: -25px;
  }

  .list-group {
    padding: 0px;
  }

  .float-left {
    float: right !important;
  }
  .float-right {
    float: left !important;
  }

  .text-left {
    text-align: right !important;
  }
  .text-right {
    text-align: left !important;
  }

  .close {
    float: left;
  }

  .comment-footer .action-icons a {
    padding-right: 7px;
    padding-left: 0;
  }

  .modal-header .close {
    float: left;
    margin: -1rem auto -1rem -1rem;
  }

  .modal-footer > :not(:last-child) {
    margin-left: 0.25rem;
    margin-right: 0;
  }

  .ml-auto {
    margin-right: auto !important;
    margin-left: 0px !important;
  }

  .mr-auto {
    margin-left: auto !important;
    margin-right: 0px !important;
  }

  .border-right {
    border-right: none !important;
    border-left: 1px solid $border-color !important;
  }

  .border-left {
    border-left: none !important;
    border-right: 1px solid $border-color !important;
  }

  .alert-dismissible {
    padding-left: 3.8125rem;
    padding-right: 1.25rem;
    .close {
      left: 0;
      right: auto;
    }
  }

  .custom-file-label {
    left: auto;
    right: 0;
    width: 100%;
    &::after {
      right: auto;
      left: 0;
    }
  }

  .dropdown-menu {
    text-align: right;
    right: 0 !important;

    &.dropdown-menu-right {
      right: auto !important;
      left: 0 !important;
      transform: translate(0px, 60px) !important;
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu {
      &.dropdown-menu-right {
        //top: $topbar-height !important;
      }
    }
  }

  .topbar .navbar-collapse {
    padding: 0 0 0 10px;
  }

  .navbar-nav,
  .nav,
  .list-unstyled,
  .pagination {
    padding-right: 0;
  }

  .topbar .top-navbar .navbar-header .navbar-brand .logo-icon {
    margin-right: 0;
  }

  .topbar .dropdown-menu.dropdown-menu-right .with-arrow {
    left: 0;
    right: auto;
  }

  .topbar .dropdown-menu.dropdown-menu-right .with-arrow > span {
    left: 20px;
    right: auto;
  }

  .search-box .app-search .srh-btn {
    right: auto;
    left: 20px;
  }

  .mailbox .message-center .message-item .mail-contnet {
    padding-right: 10px;
    padding-left: 0;
  }

  .sidebar-nav ul .sidebar-item .sidebar-link .side-badge.badge {
    left: 32px;
    right: unset;
  }

  .user-profile {
    padding: 10px 10px 10px 0;
  }

  .user-profile span img {
    margin-right: 0;
    margin-left: 10px;
  }

  .customizer {
    left: -280px;
    right: auto;
    &.show-service-panel {
      left: 0;
      right: auto;
      .service-panel-toggle {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 100%;
        border-top-right-radius: 100%;
        right: -55px;
      }
    }
    .service-panel-toggle {
      right: -70px;
      left: auto;
    }
  }

  .sidebar-nav .has-arrow::after {
    margin-left: 0;
    margin-right: 10px;
    right: auto;
    left: 15px;
  }

  /* Stickey inner-left-part */

  $left-part-width: 260px;

  .left-part {
    border-left: 1px solid $border-color;
    border-right: none;
    .show-left-part {
      left: -41px;
      right: auto;
    }
  }

  .right-part {
    margin-right: $left-part-width;
    margin-left: 0;
    .dropdown-menu {
      right: auto !important;
      left: 0 !important;
    }
  }

  .reverse-mode {
    .left-part {
      right: auto;
      left: 0;
    }
    .show-left-part {
      left: auto;
      right: -41px;
    }
    .right-part {
      margin-right: 0px;
      margin-left: $left-part-width;
    }
  }

  .profiletimeline {
    padding-right: 40px;
    padding-left: 0;
    margin: 40px 30px 0 10px;
    border-right: 1px solid $border-color;
    border-left: none;
    .sl-left {
      float: right;
      margin-right: -60px;
      margin-left: 15px;
    }
  }

  .feed-widget .feed-body .feed-item > .feed-icon {
    margin-right: 0;
    margin-left: 10px;
  }

  .chat-list {
    .chat-item {
      .chat-content {
        padding-right: 15px;
        padding-left: 0;
      }
      .chat-time {
        margin: 5px 65px 15px 0;
      }
      &.odd .chat-content,
      &.odd .chat-time {
        text-align: left;
      }
    }
  }

  /* plugins */

  div.dataTables_wrapper div.dataTables_filter {
    text-align: left;
  }

  table.table-bordered.dataTable th,
  table.table-bordered.dataTable td {
    border-left-width: 1px;
  }

  div.dataTables_wrapper div.dataTables_filter input {
    margin-right: 0.5rem;
    margin-left: 0;
  }

  div.table-responsive
    > div.dataTables_wrapper
    > div.row
    > div[class^="col-"]:last-child {
    padding-left: 1px;
  }

  .lobilists {
    .lobilist-actions {
      right: auto;
      left: 8px;
    }
    .lobilist-item .todo-actions {
      right: auto;
      left: 4px;
    }
    .lobilist-check {
      left: auto;
      right: 12px;
    }
    .lobilist-item,
    .lobilist-item-placeholder {
      padding-left: 0;
      padding-right: 35px;
    }
    .lobilist-item .drag-handler {
      left: auto;
      right: 0;
    }
    .lobilist-wrapper,
    .lobilist-placeholder {
      margin-left: 16px;
      margin-right: 0;
    }
  }

  .datepicker {
    direction: rtl;
  }

  .minicolors .minicolors-grid {
    right: 22px;
    left: auto;
  }

  .wizard > .steps > ul > li,
  .wizard > .actions > ul > li {
    float: right;
  }

  .wizard-content .wizard > .steps > ul > li:after {
    right: auto;
    left: 0;
  }

  .wizard-content .wizard > .steps > ul > li:before {
    left: auto;
    right: 0;
  }

  .wizard-content .wizard.vertical > .steps {
    float: right;
  }

  /*.sl-icon i,
    [class*=" ti-"],
    [class^=ti-] {
        float: right;
        margin-left: 10px;
        line-height: 20px;
    }*/

  .css-bar > i {
    margin-left: 0;
    margin-right: 5px;
  }

  .treeview span.icon {
    margin-right: 0;
    margin-left: 5px;
  }

  &#main-wrapper {
    /*sidebar type*/

    &[data-sidebartype="full"] {
      .page-wrapper {
        margin-right: $sidebar-width-full;
        margin-left: 0;
      }
    }

    &[data-sidebartype="iconbar"] {
      .page-wrapper {
        margin-right: $sidebar-width-iconbar;
        margin-left: 0;
      }
    }

    &[data-sidebartype="overlay"] {
      .left-sidebar {
        right: -$sidebar-width-full;
        left: auto;
      }
      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: auto;
        }
      }
    }
  }

  .dz-hidden-input {
    display: none;
  }

  // apexchart tooltip rtl

  .apexcharts-tooltip-series-group {
    text-align: right;
  }

  .apexcharts-tooltip-marker {
    margin-right: 0;
    margin-left: 10px;
  }

  .apexcharts-tooltip-text-value,
  .apexcharts-tooltip-text-z-value {
    margin-left: 0;
    margin-right: 5px;
  }

  /* Vertical responsive layout */

  @include media-breakpoint-up(lg) {
    &#main-wrapper {
      /*Sidebar position*/

      &[data-sidebar-position="fixed"][data-sidebartype="full"],
      &[data-sidebar-position="fixed"][data-sidebartype="overlay"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-full;
          margin-left: 0;
        }
      }

      &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }

      &[data-sidebar-position="fixed"][data-sidebartype="iconbar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-iconbar;
          margin-left: 0;
        }
      }

      /*Fixed sidebar with minisidebar*/

      &[data-sidebar-position="fixed"][data-sidebartype="mini-sidebar"] {
        .topbar .top-navbar .navbar-collapse {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }

      &[data-sidebartype="mini-sidebar"] {
        .page-wrapper {
          margin-right: $sidebar-width-mini;
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 766px) {
    &#main-wrapper {
      &[data-sidebartype="mini-sidebar"] {
        .left-sidebar {
          right: -$sidebar-width-full;
          left: auto;
        }
      }

      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: auto;
        }
      }
    }

    .left-part {
      right: -260px;
      left: auto;
      &.show-panel {
        right: 0px;
        left: auto;
      }
    }

    .right-part {
      margin-right: 0px;
    }
  }

  // new

  @media (max-width: 767px) {
    .topbar .top-navbar {
      .dropdown-menu {
        left: auto !important;
        right: 0 !important;
        transform: translate(0px, 64px) !important;
      }
    }
  }

  @include media-breakpoint-down(md) {
    &#main-wrapper {
      &[data-sidebartype="mini-sidebar"] {
        .left-sidebar {
          left: unset;
          right: -250px;
        }
      }
      &.show-sidebar {
        .left-sidebar {
          right: 0;
          left: unset;
        }
      }
    }
  }
}
