.feeds {
  margin: 0px;
  padding: 0px;
  li {
    list-style: none;
    padding: 10px;
    display: block;
    &:hover {
      background: $gray-100;
    }
    span {
      font-size: 12px;
    }
  }
}

.feed-body {
  .feed-item {
    padding: 12px;
    display: flex;
    align-items: center;
    font-size: 14px;
    background-color: transparent;
    &:hover {
      background-color: $gray-100;
    }
    .feed-icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      vertical-align: middle;
      border-radius: 100%;
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
